import React from "react"
import styled from "styled-components"
import { Container, mediaQuery } from "../../theme"
import { getImage, GatsbyImage } from "gatsby-plugin-image"

const ImageContainer = styled.div`
  width: 100%;
  height: 400px;
  position: relative;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }

  ${mediaQuery("md")} {
    height: 300px;
  }
  ${mediaQuery("sm")} {
    height: 250px;
  }

  &::after {
    background: linear-gradient(
      180deg, rgba(16,28,61,1) 0%, rgba(255,255,255,0) 40%);
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;

  }
`

const Heading = styled.h1`
  font-family: DM Sans;
  font-style: normal;
  font-weight: bold;
  font-size: 60px;
  line-height: 115%;
  letter-spacing: -1.88px;
  color: #18191f;
  margin-top: 16px;

  ${mediaQuery("md")} {
    font-size: 35px;
    margin-bottom: 8px;
  }
  ${mediaQuery("sm")} {
    font-size: 30px;
  }
`

const Paragraph = styled.div`
  p {
    font-family: DM Sans;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 175%;
    color: #18191f;
  }

  strong {
    color: #ef5da8;
  }
  ${mediaQuery("md")} {
    font-size: 15px;
  }
`
const TextContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr;
  grid-template-columns: 1fr 1fr;
  margin-top: 32px;
  ${mediaQuery("md")} {
    display: block;
  }
`

export const Feature = ({ image, heading, paragraph }) => {
  return (
    <>
      <ImageContainer>
        <GatsbyImage image={getImage(image)} />
      </ImageContainer>
      <Container>
        <TextContainer>
          <Heading>{heading}</Heading>
          <Paragraph
            dangerouslySetInnerHTML={{
              __html: paragraph,
            }}
          />
        </TextContainer>
      </Container>
    </>
  )
}
